.axis path,
.axis line {
  fill: none;
  stroke: black;
  shape-rendering: crispEdges;
}

.axis text {
  font-size: 14px;
  font-family: "Arial", sans-serif;
}
